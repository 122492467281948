import { reactive } from 'vue';

const userStore = reactive({
  user: null,
  token: null,

  setUser(userInfo) {
    this.user = userInfo;
    localStorage.setItem('user', JSON.stringify(userInfo));
  },

  getUser() {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  },

  clearUser() {
    this.user = null;
    localStorage.removeItem('user');
  },

  setToken(token) {
    this.token = token;
    localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  clearToken() {
    this.token = null;
    localStorage.removeItem('token');
  },
});

export default userStore;
