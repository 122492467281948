<!--
 * @Author: 王大坤 160484192@qq.com
 * @Date: 2024-01-02 14:37:50
 * @LastEditors: 王大坤 160484192@qq.com
 * @LastEditTime: 2024-01-21 01:21:19
 * @FilePath: /view-ui-project-vuecli/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>
