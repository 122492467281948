<template>
  <div class="tabbar_view">
    <div class="tabbar_view_item" :class="[path == item.path ? 'active' : '']" v-for="(item, index) in navbars"
      :key="index" @click="switchTab(item.path)">
      <img :src="path == item.path ? item.active_icon : item.icon" class="tabbar_view_item_icon" />
      <p class="tabbar_view_item_text">{{ item.name }}</p>
    </div>
  </div>
</template>
<script setup>
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";

import a1 from '@/assets/TabBar/a1.png';
import a from '@/assets/TabBar/a.png';
import b1 from '@/assets/TabBar/b1.png';
import b from '@/assets/TabBar/b.png';
import c1 from '@/assets/TabBar/c1.png';
import c from '@/assets/TabBar/c.png';
import d1 from '@/assets/TabBar/d1.png';
import d from '@/assets/TabBar/d.png';
import e1 from '@/assets/TabBar/e1.png';
import e from '@/assets/TabBar/e.png';
const navbars = reactive([
  {
    name: "AI提问",
    active_icon: a1,
    icon: a,
    path: "/",
  },
  {
    name: "AI绘画",
    active_icon: c1,
    icon: c,
    path: "/mj",
  },
  {
    name: "绘画广场",
    active_icon: d1,
    icon: d,
    path: "/originality",
  },
  {
    name: "商店",
    active_icon: b1,
    icon: b,
    path: "/shop",
  },
    {
    name: "我的",
    active_icon: e1,
    icon: e,
    path: "/me",
  },
]);
const route = useRoute();
const router = useRouter();
let path = route.path;
const switchTab = (path) => {
  router.push(path);
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>