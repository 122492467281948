/*
 * @Author: 王大坤 160484192@qq.com
 * @Date: 2024-01-02 14:37:50
 * @LastEditors: 王大坤 160484192@qq.com
 * @LastEditTime: 2024-01-21 17:42:43
 * @FilePath: /view-ui-project-vuecli/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import userStore from '@/common/userStore.js';
import api from '@/common/api.js';
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
import '../my-theme/index.less';
import OSInfo from '@/common/os'
// import './mock'
import home from './components/home.vue'
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import { post } from "@/common/api";
import VueMarkdownEditor from '@kangc/v-md-editor';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

// import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
// import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
// highlightjs
import hljs from 'highlight.js';
// 创建 script 元素，并指定 vConsole 的 CDN 链接
// const script = document.createElement('script');
// script.src = 'https://cdn.jsdelivr.net/npm/vconsole@latest/dist/vconsole.min.js';
// script.onload = () => {
// //   // 创建一个 vConsole 实例
//   new window.VConsole();
// };

// document.head.appendChild(script);
// VueMarkdownEditor.use(createCopyCodePlugin());
// VueMarkdownEditor.use(createHighlightLinesPlugin());
import wxPay from '@/common/jssdk'
VMdPreview.use(githubTheme, {
    Hljs: hljs,
});
VMdPreview.use(createCopyCodePlugin());
// VMdPreview.use(createHighlightLinesPlugin());
const app = createApp(App)
const meta = document.createElement('meta');
meta.name = 'viewport';
meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
document.getElementsByTagName('head')[0].appendChild(meta);
let os =  OSInfo.getOSInfo()
os.then(result => {
  const currentOs = result;
  
  // const currentOs = 'iOS';
  window.currentOs = currentOs;
  if(currentOs == 'iOS'){
    wxPay.getWxPaySign({url:location.href.split("#")[0]})
    
    post("api/getUserInfo", {}).then((result) => {
      if (result.code === 1000) {
         this.$Message.warning(result.message);
         return;
       }
       userStore.setUser(result.data);
       }).catch((error) => {
           console.error("Error:", error);
       });
  }
  app.provide('currentOs', currentOs);
});

app.component('home',home);
app.config.globalProperties.$userStore = userStore;
app.config.globalProperties.$api = api;
app.use(store)
  .use(router)
  .use(ViewUIPlus)
  .use(VMdPreview)
  .mount('#app')
app.provide('router', router);
