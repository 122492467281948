import wx from 'weixin-js-sdk'
import { post } from "@/common/api";
export default {
  
  // 获取微信支付SDK的签名
  getWxPaySign (params) {
    // return new Promise((resolve) => {
      // 发起请求获取签名

      post("api/jsSdk", params).then((data) => {
        const wxConfig = {
          appId: data.data.appId,
          timestamp: data.data.timestamp,
          nonceStr: data.data.nonceStr,
          signature: data.data.signature,
        }
        
        // 初始化微信 JSSDK
        // function initWeChat() {
          wx.config({
            debug: false, // 开启调试模式
            appId: wxConfig.appId, // 必填，公众号的唯一标识
            timestamp: wxConfig.timestamp, // 必填，生成签名的时间戳
            nonceStr: wxConfig.nonceStr, // 必填，生成签名的随机串
            signature: wxConfig.signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的 JS 接口列表
          })
        
          wx.ready(() => {
            // 设置全局分享内容
            const shareData = {
              title: '智脑精灵AI',
              desc: '智脑精灵AI支持OpenAI最新的gpt4o大模型，快捷稳定。支持MjAI绘图与SD绘图',
              link: 'https://wx.cdhbx.cn/?isH5=1',
              imgUrl: 'https://img.cdhbx.cn/uploads/mj/20240530/04debb2ff62c18b3e39243049b438c08.png'
            }
        
            wx.updateAppMessageShareData(shareData) // 分享给朋友
            wx.updateTimelineShareData(shareData) // 分享到朋友圈
          })
        
          wx.error((res) => {
            console.error('微信配置错误:', res)
          })
        // }
        
        // 在 Vue 实例创建之前初始化微信 JSSDK
        // initWeChat()
        // 配置微信JS SDK
        // wx.config({
        //   debug: false,
        //   appId: data.data.appId,
        //   timestamp: data.data.timestamp,
        //   nonceStr: data.data.nonceStr,
        //   signature: data.data.signature,
        //   jsApiList: data.data.jsApiList,
        //   openTagList: data.data.openTagList,
        // })
        console.log('配置完成')
        // 配置完成后返回一个resolve
        // wx.ready(() => {
        //   console.log('进来了')
        //   let imgUrl = 'https://img.cdhbx.cn/uploads/mj/20240530/04debb2ff62c18b3e39243049b438c08.png'
        //   let desc = '智脑精灵AI支持OpenAI最新的gpt4o大模型，快捷稳定。支持MjAI绘图与SD绘图'
        //   let link = 'https://wx.cdhbx.cn/?isH5=1'
        //   wx.updateAppMessageShareData({
        //     title: '智脑精灵AI', // 分享标题
        //     desc: desc, // 分享描述
        //     link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl: imgUrl, // 分享图标
        //     success: () => {
        //       // Toast('分享成功')
        //       console.log('updateAppMessageShareData分享成功')
        //     },
        //     fail: (err) => {
        //       // 设置失败
        //       console.log('updateAppMessageShareData分享失败')
        //       // Toast('发送好友分享模板设置失败')
        //     }
        //   })
          // wx.updateTimelineShareData({
          //   title: '智脑精灵AI', // 分享标题
          //   link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          //   imgUrl: imgUrl, // 分享图标
          //   success: () => {
          //     console.log('updateTimelineShareData朋友圈分享成功')
          //     // Toast('朋友圈分享模板设置成功')
          //   },
          //   fail: (err) => {
          //     // 设置失败
          //     // Toast('朋友圈分享模板设置失败')
          //     console.log('updateTimelineShareData朋友圈分享失败')
          //   }
          // })
        // })
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
    // })
  },

  // 发起微信支付
  wxPay (params) {
    wx.config({
      debug: false,
      appId: params.appId,
    })
    return new Promise((resolve, reject) => {
      // 调用微信支付
      wx.chooseWXPay({
        timestamp: params.timestamp,  
        nonceStr: params.nonceStr,  
        package: params.package,  
        signType: params.signType,  
        paySign: params.paySign,  
        success: (res) => {
          // 支付成功时返回resolve
          resolve(res)
        },
        fail: (err) => {
          // 支付失败时返回reject
          reject(err)
        }
      })
    })
  }
}
