/*
 * @Author: 王大坤 160484192@qq.com
 * @Date: 2024-01-02 14:37:50
 * @LastEditors: 王大坤 160484192@qq.com
 * @LastEditTime: 2024-01-21 17:49:09
 * @FilePath: /view-ui-project-vuecli/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/user/Login.vue'
const currentOs = window.currentOs;
console.log(currentOs,'路由')
const routes = [
  
  {
    path: '/user/login',
    name: 'user/login',
    component: Login,
    meta: {
      title: '智脑精灵AI-用户登陆'
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat.vue')
    ,meta: {
      title: '智脑精灵AI-AI问答'
    }
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('@/views/user/me.vue')
    ,meta: {
      title: '智脑精灵AI-个人空间'
    }
  },
  {
    path: '/sd',
    name: 'sd',
    component: () => import('@/views/sd.vue')
    ,meta: {
      title: '智脑精灵AI-SD魔幻绘图'
    }
  },
  {
    path: '/meSettings',
    name: 'meSettings',
    component: () => import('@/views/user/meSettings.vue')
    ,meta: {
      title: '智脑精灵AI-我的设置'
    }
  },
  {
    path: '/mj',
    component: () => import('@/views/mj.vue')
    ,meta: {
      title: '智脑精灵AI-MJ魔幻绘图'
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/shop.vue')
    ,meta: {
      title: '智脑精灵AI-商城'
    }
  },
  {
    path: '/community',
    name: '/community',
    component: () => import('@/views/community.vue')
    ,meta: {
      title: '智脑精灵AI-社区'
    }
  },
  {
    path: '/originality',
    name: '/originality',
    component: () => import('@/views/originality.vue')
    ,meta: {
      title: '智脑精灵AI-创意广场'
    }
  },
  {
    path: '/paySuccess',
    name: '/paySuccess',
    component: () => import('@/views/paySuccess.vue')
    ,meta: {
      title: '智脑精灵AI-充值成功'
    }
  },
  {
    path: '/user/meOrder',
    name: '/meOrder',
    component: () => import('@/views/user/meOrder.vue')
    ,meta: {
      title: '智脑精灵AI-我的订单'
    }
  },
  {
    path: '/user/mePayDetail',
    name: '/mePayDetail',
    component: () => import('@/views/user/mePayDetail.vue')
    ,meta: {
      title: '智脑精灵AI-消费明细'
    }
  },
  {
    path: '/user/cdk',
    name: '/cdk',
    component: () => import('@/views/user/cdk.vue')
    ,meta: {
      title: '智脑精灵AI-卡密兑换'
    }
  },
  {
    path: '/user/sign',
    name: '/sign',
    component: () => import('@/views/user/sign.vue')
    ,meta: {
      title: '智脑精灵AI-签到'
    }
  },
  {
    path: '/user/kefu',
    name: '/kefu',
    component: () => import('@/views/user/kefu.vue')
    ,meta: {
      title: '智脑精灵AI-客服'
    }
  },
  {
    path: '/user/about',
    name: '/about',
    component: () => import('@/views/user/about.vue')
    ,meta: {
      title: '智脑精灵AI-关于我们'
    }
  },
  
]
const urlParams = new URLSearchParams(window.location.search);
const isH5 = urlParams.get('isH5');
if (isH5 == 1) {
  routes.push({
    path: '/',
    name: 'home',
    component: () => import('@/views/chat.vue'),
    meta: {
      title: '智脑精灵AI'
    }
  })
}else{
  routes.push({
    path: '/',
    name: 'home',
    component: () => import('@/views/originality.vue'),
    meta: {
      title: '智脑精灵AI'
    }
  })
}
const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})
router.beforeEach((to, from, next) => {
  // 检查路由的 meta 是否需要鉴权
  document.title = to.meta.title || '智脑精灵AI'; // 如果 meta 中没有设置标题，则使用默认标题
  if (to.meta.requiresAuth  && to.path !== '/user/login') {
    // 在这里执行你的鉴权逻辑，比如检查用户是否登录
    const isAuthenticated = checkAuth() // 自定义的鉴权函数

    if (isAuthenticated) {
      // 用户已登录，允许访问
      next()
    } else {
      // 用户未登录，重定向到登录页面
      next('/user/login')
    }
  } else {
    // 不需要鉴权的路由，直接放行
    next()
  }
})

// 检查用户是否登录的示例函数
function checkAuth() {
  // 实际项目中，可能需要从后端或本地存储中检查用户登录状态
  // 这里简单返回一个假值作为示例
  return false // 用户已登录
}
export default router
