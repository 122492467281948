/*
 * @Author: 王大坤 160484192@qq.com
 * @Date: 2024-01-02 15:41:24
 * @LastEditors: 王大坤 160484192@qq.com
 * @LastEditTime: 2024-03-26 23:30:31
 * @FilePath: /view-ui-project-vuecli/src/common/api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// api.js

import axios from 'axios';
import userStore from './userStore';
import { Message } from 'view-ui-plus';

export function showMessage(message) {
    Message.warning({
        content: message
    });
}
// axios.defaults.proxy = {
//     host : 'http://localhost',
//     port : 8000,
// }
export const baseURL = 'https://wx.cdhbx.cn/index.php/'; // 替换为你的实际接口地址
// export const baseURL = '/'; // 替换为你的实际接口地址

const instance = axios.create({
    baseURL,
    timeout: 500000, // 设置超时时间
});

export const get = async (url, params = {}) => {
    try {
        url = baseURL + url;
        const response = await instance.get(url, { params });
        return response.data;
    } catch (error) {
        // 处理错误
        console.error('Request failed:', error);
        throw error;
    }
};

export const post = async (url, data = {}) => {
    try {
        url = baseURL + url;
        var user = userStore.getUser();
        const headers = {
            'Content-Type': 'application/json'
        };
        
        if (user && user.token) {
            headers['token'] = user.token;
        }
        
        const response = await instance.post(url, data, { headers: headers });
        console.log(window.currentOs,'api')
        if(window.currentOs !== 'iOS'){
            if (response.data.code === 1001) {
                // showMessage(response.data.message);
                // setTimeout(function (){
                    userStore.clearUser();
                    userStore.clearToken();
                    // window.location.reload();
                    window.location.href = "/user/login";
                // },1500)
            }
        }
        return response.data;
    } catch (error) {
        // 处理错误
        console.error('Request failed:', error);
        throw error;
    }
};
export const postStream = (url, data = {}) => {
    try {
        const fullUrl = baseURL + url;

        // 创建 EventSource 对象并指定服务器端点
        const eventSource = new EventSource(fullUrl);

        // 在接收到消息时处理事件
        eventSource.onmessage = (event) => {
            const message = JSON.parse(event.data);
            // 在这里处理每条消息
            console.log('Received message:', message);
        };

        // 在连接发生错误时处理事件
        eventSource.onerror = (error) => {
            console.error('EventSource error:', error);
        };

    } catch (error) {
        console.error('Request failed:', error);
        throw error;
    }
};

// export const postStream = async (url, data = {}) => {
//     try {
//         const fullUrl = baseURL + url;
//         const response = await axios({
//             method: 'post',
//             url: fullUrl,
//             data: data,
//             responseType: 'stream',
//         });
//
//         // 手动处理流式数据
//         response.data.on('data', (chunk) => {
//             // 在这里处理每个数据块
//             console.log('Received chunk:', chunk);
//         });
//
//         response.data.on('end', () => {
//             console.log('Stream ended');
//         });
//
//         response.data.on('error', (error) => {
//             console.error('Stream error:', error);
//         });
//
//     } catch (error) {
//         console.error('Request failed:', error);
//         throw error;
//     }
// };
// 可以根据需要添加其他 HTTP 请求方法，例如 put、delete 等
