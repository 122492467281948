<!--
 * @Author: 王大坤 160484192@qq.com
 * @Date: 2024-01-02 14:37:50
 * @LastEditors: 王大坤 160484192@qq.com
 * @LastEditTime: 2024-03-26 22:49:04
 * @FilePath: /view-ui-project-vuecli/src/views/Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="layout">
    <Layout>
      <Header v-if="title!=='智脑精灵AI-个人空间'" :style="{ position: 'fixed', width: '100%', 'z-index': 2}">
        <div style="width: 100%;position: relative;" v-if="title!=='智脑精灵AI-个人空间'">
          <!-- <Icon style="position: absolute;left: 0;top: 20px;" type="md-list" size="24" v-if="!isNav" @click="isMenu = true"/> -->
          <h3 style="text-align: center;" v-if="!isNav">{{ title }}</h3>
        </div>
        
        <Menu mode="horizontal" v-if="isNav" theme="light" :active-name="activity.toString()">
<!--          <div class="layout-logo">-->
<!--            <img-->
<!--              style="width: 100%; margin-bottom: 5px"-->
<!--              src="https://file.iviewui.com/view-design-dist/img/logo.362917a9.png"-->
<!--            />-->
<!--          </div>-->
          <div class="layout-nav">
            <MenuItem name="1" to="/originality">
              <Icon size="16" type="logo-instagram" />
              绘图广场
            </MenuItem>
            <MenuItem name="2" to="/chat">
              <Icon size="16" type="ios-navigate"></Icon>
              AI问答
            </MenuItem>
            <MenuItem name="3" to="/mj">
              <Icon size="16" type="ios-keypad"></Icon>
              MJ魔幻绘图
            </MenuItem>
            <MenuItem name="7" to="/sd">
              <Icon size="16" type="ios-keypad"></Icon>
              SD魔幻绘图
            </MenuItem>
            <MenuItem name="4" to="/shop">
              <Icon size="16" type="md-basket" />
              商城
            </MenuItem>
            <!-- <MenuItem name="5" to="/community">
              <Icon type="ios-analytics"></Icon>
              留言板
            </MenuItem> -->
            <MenuItem name="6">
              <Avatar @click="toMe" size="24" style="cursor: pointer" :src="userData.head" v-if="userData" />
              <Dropdown style="margin-left: 10px;">
                个人中心
                <Icon type="ios-arrow-down"></Icon>
                <template #list>
                  <DropdownMenu>
                    <DropdownItem v-if="userData">{{userData.nick}}</DropdownItem>
                    <DropdownItem v-if="userData">积分: {{userData.score}}</DropdownItem>
                    <DropdownItem v-if="userData">tokens: {{userData.tokens}}</DropdownItem>
                    <DropdownItem @click="toPath('meOrder')">我的订单</DropdownItem>
                    <!-- <DropdownItem @click="toPath('mePayDetail')">消费明细</DropdownItem> -->
                    <!-- <DropdownItem>分享推广</DropdownItem> -->
                    <DropdownItem @click="toSettings">个人设置</DropdownItem>
                    <DropdownItem divided @click="logout" style="text-align: center"
                      ><span style="color: red" >退出登录</span></DropdownItem
                    >
                  </DropdownMenu>
                </template>
              </Dropdown>
            </MenuItem>
            <!-- <MenuItem name="8">
              <Icon type="md-notifications" size="20" />
            </MenuItem> -->
            <Button size="small" type="warning" v-if="userData == null" @click="login">登录/注册</Button>
          </div>
        </Menu>
      </Header>
      <Content
        :style="{ margin: !isNav ? '58px 5px 0' :'88px 5px 0', transition: 'all .2s ease-in-out'}"
      >
      <Modal v-model="submitFank" title="请留下你的宝贵意见" footer-hide="false">
        <Form :model="formItem" :label-width="80">
        <FormItem label="主题">
            <Input v-model="submitFormItem.title" placeholder="请输入大致反馈内容"></Input>
        </FormItem>
        <FormItem label="提交类型">
            <Select v-model="submitFormItem.type">
                <Option value="BUG">BUG</Option>
                <Option value="修改意见">修改意见</Option>
                <Option value="其他">其他</Option>
            </Select>
        </FormItem>
        <FormItem label="反馈内容">
            <Input v-model="submitFormItem.content" type="textarea" :rows="5" placeholder="请输入反馈内容"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="submitFankForm">提交</Button>
            <Button style="margin-left: 8px" @click="submitFank = false">取消</Button>
        </FormItem>
    </Form>
    <p style="text-align: center;">积分奖励🥰</p>
      </Modal>
      <div @click="showFk" style="width: 50px;height: 50px;background-color: rgba(200,200,200,0.7);position: fixed;right: 20px;bottom: 200px;border-radius: 50%;z-index: 99999;color: white;line-height: 50px;text-align: center;cursor: pointer;">反馈</div>
        <slot> </slot>

        <!-- </Card> -->
        <!-- <Footer class="layout-footer-center" v-if="isNav"
          >2023-2024 &copy; 成都海边星科技 <a style="color:#515a6e" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024051673号-1</a>
          <img src="../assets/beianIcon.png" style="width: 10px;margin-right: 5px; line-height: 12px;margin-left: 20px"/><a  style="color:#515a6e" href="https://beian.mps.gov.cn/#/query/webSearch?code=51011502000686" rel="noreferrer" target="_blank">川公网安备51011502000686</a>
          <p>
            代理域名注册服务机构：广州云讯信息科技有限公司或腾讯云计算（北京）有限责任公司
          </p>
        </Footer
        > -->
      </Content>
    </Layout>
    <Modal
      v-model="loginStatus"
      :width="386"
      :mask="false"
      class-name="vertical-center-modal"
      :footer-hide="true"
      style="margin-top: 150px;"
      @on-cancel="onLoginCancel"
    >
      <div class="demo-login">
        <h1 style="text-align: center">欢迎登陆</h1>
        <Tabs
          v-model="tabsIndex"
          :events-enabled="true"
          @on-click="handleChangeTab"
        >
          <TabPane label="微信登陆" name="1" @on-click="handleChangeTab">
            <div style="width: 70%; margin: 0 auto">
              <Image
                :src="wxLoginUrl"
                :fit="fit"
                width="100%"
                height="100%"
                :alt="fit"
              >
                <template #error>
                    <Icon type="ios-loading" size="24" color="#ccc" />
                </template>
              </Image>
              <Tag
                checkable
                color="success"
                v-if="state === 1"
                style="margin-top: 10px; margin-left: 38%"
                >已扫码</Tag
              >
            </div>
          </TabPane>
          <TabPane label="账号密码" name="2">
            <Login @on-submit="handleSubmit">
              <UserName name="username" />
              <Password name="password" />
              <div class="demo-auto-login" style="overflow: hidden">
                <a style="float: left">去注册</a>
                <a>忘记密码</a>
              </div>
              <Submit />
            </Login>
          </TabPane>
          <TabPane label="手机号登陆" name="3">
            <Login @on-submit="handlePhoneSubmit">
              <Mobile name="mobile" />
              <Captcha
                name="captcha"
                :field="['mobile']"
                @on-get-captcha="handleGetCaptcha"
              />
              <Submit />
            </Login>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  </div>
  <TabBar v-if="!isNav"></TabBar>
  <Drawer placement="left" :closable="false" v-model="isMenu">
    <!-- <List size="large">
            <ListItem>This is a piece of text.</ListItem>
            <ListItem>This is a piece of text.</ListItem>
            <ListItem>This is a piece of text.</ListItem>
        </List> -->
        <Menu style="width: 100%;" mode="vertical" theme="light" :active-name="activity.toString()">
<!--          <div class="layout-logo">-->
<!--            <img-->
<!--              style="width: 100%; margin-bottom: 5px"-->
<!--              src="https://file.iviewui.com/view-design-dist/img/logo.362917a9.png"-->
<!--            />-->
<!--          </div>-->
          <div class="layout-nav">
            <MenuItem name="1" to="/originality">
              <Icon size="16" type="logo-instagram" />
              绘图广场
            </MenuItem>
            <MenuItem name="2" to="/chat">
              <Icon size="16" type="ios-navigate"></Icon>
              AI问答
            </MenuItem>
            <MenuItem name="3" to="/mj">
              <Icon size="16" type="ios-keypad"></Icon>
              MJ魔幻绘图
            </MenuItem>
            <MenuItem name="7" to="/sd">
              <Icon size="16" type="ios-keypad"></Icon>
              SD魔幻绘图
            </MenuItem>
            <MenuItem name="4" to="/shop">
              <Icon size="16" type="md-basket" />
              商城
            </MenuItem>
            <!-- <MenuItem name="5" to="/community">
              <Icon type="ios-analytics"></Icon>
              留言板
            </MenuItem> -->
            <Submenu name="6" >
              <template #title>
                <Avatar @click="toMe" size="23" style="margin-right: 2px; cursor: pointer" :src="userData.head" v-if="userData" />
              个人中心
              <Icon type="ios-arrow-down"></Icon>
                    </template>
              
              <!-- <Dropdown> -->
                    
                    <MenuItem v-if="userData">{{userData.nick}}</MenuItem>
                    <MenuItem v-if="userData">积分: {{userData.score}}</MenuItem>
                    <MenuItem v-if="userData">tokens: {{userData.tokens}}</MenuItem>
                    <MenuItem v-if="userData" @click="toPath('meOrder')">我的订单</MenuItem>
                    <!-- <DropdownItem @click="toPath('mePayDetail')">消费明细</DropdownItem> -->
                    <!-- <DropdownItem>分享推广</DropdownItem> -->
                    <MenuItem v-if="userData" @click="toSettings">个人设置</MenuItem>
                    <MenuItem divided @click="logout" style="text-align: center">
                      <span style="color: red" >退出登录</span>
                    </MenuItem>
                <!-- <template #list>
                  <DropdownMenu>
                    
                  </DropdownMenu>
                </template> -->
              <!-- </Dropdown> -->
            </Submenu>
            <!-- <MenuItem name="8">
              <Icon type="md-notifications" size="20" />
            </MenuItem> -->
            
            <!-- <Button size="small" type="warning" v-if="userData == null" @click="login">登录/注册</Button> -->
          </div>
        </Menu>
        </Drawer>
</template>
<script >
import "@/common/api";
import OSInfo from '@/common/os'
import { post } from "@/common/api";
import { ref } from "vue";
import TabBar from './tabbar/TabBar.vue'
import {
  Avatar,
  Captcha,
  Content, Dropdown, DropdownItem, DropdownMenu,
  Icon,
  Image, Layout, MenuItem,
  Mobile,
  Modal,
  Password,
  Spin,
  Submit,
  TabPane,
  Tabs,
  UserName
} from "view-ui-plus";
export default {
  props: ['activity'],
  name: "HomeView",
  components: {
    Layout,
    DropdownMenu,
    Dropdown,
    DropdownItem,
    MenuItem,
    TabBar,
    Avatar, Captcha, Tabs, Modal, Content, Mobile, Submit, Image, Password, UserName, TabPane, Icon, Spin},
  data() {
    return {
      submitFormItem:{
          title:'',
          type:'',
          content:''
      },
      submitFank:false,
      loginStatus: false,
      autoLogin: true,
      state: 0,
      // fit: true,
      tabsIndex: 0,
      wxLoginUrl: "",
      timer: null,
      codeId: 0,
      userData:null,
      fit: 'cover', // 图片适应方式
      isLoading: true, // 是否在加载中
      meUrl:'',
      isNav:false,
      isMenu:false,
      title:''
    };
  },
  inject: ['currentOs'],
  mounted() {
    console.log(this.currentOs); // 输出 'globalValue'
  },
  created() {
      this.title = document.title
      this.operatingSystem = this.currentOs
      if(this.operatingSystem == 'iOS'){
        this.isNav = false
      }else{
        this.isNav = true
      }
    this.$emit('removeEventListener');
    console.log(this.activity,'传入的值')
    if(this.activity != '1'){
      post("api/getUserInfo", {}).then((result) => {
       if (result.code === 1000) {
          this.$Message.warning(result.message);
          return;
        }
        this.$userStore.setUser(result.data);
        }).catch((error) => {
            console.error("Error:", error);
        });
    }
    this.userData = this.$userStore.getUser();
    if(this.userData){
      this.meUrl = "/me?userId="+this.userData.id
    }

  },
  methods: {
    toMe(){
      window.location.href = this.meUrl
    },
    toSettings(){
      console.log('进来了')
      window.location.href = '/meSettings'
    },
    toPath(ptah){
      window.location.href = '/user/'+ptah
    },
    handleImageLoad() {
      this.isLoading = false;
    },
    onLoginCancel(){
      this.loginStatus = false
      clearInterval(this.timer);
    },
    showFk(){
      this.submitFank = true
    },
    logins(){
      this.yue = 200000 + '元'
    },
    login() {
      // this.loginStatus = true
      // this.wxLoginUrl = null
      // this.createCode()
      this.$router.push({ name: "user/login" });
      // const result = post("auth/login", {
      //   username: "admin",
      //   password: "user@example.com",
      // })
      //   .then((result) => {
      //     // 这里的 result 就是 PromiseResult
      //     if (result.code === 1000) {
      //       this.$Message.warning(result.message);
      //       return;
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
      // return;
    },
    logout() {
      this.$userStore.clearUser();
      this.$userStore.clearToken();
      this.$Message.success("退出登录成功");
      setTimeout(() => {
        this.$router.push({ name: "user/login" });
      }, 1500);
    },
    createCode() {
      post("api/auth/getSanQrCodeLogin", {})
        .then((result) => {
          console.log(result.code);
          if (result.code === 1000) {
            this.$Message.warning(result.message);
            return;
          }
          this.wxLoginUrl = result.data.url;
          this.timer = setInterval(() => {
            this.wxLoginUrl = result.data.url;
            this.codeId = result.data.id;
            post("api/auth/getScanLoginDetail", { id: this.codeId })
              .then((result) => {
                if (typeof result.data.token !== "undefined") {
                  this.$userStore.setUser(result.data.user);
                  this.$userStore.setToken(result.data.token);
                  this.$Message.success("登录成功");
                  clearInterval(this.timer);
                  setTimeout(() => {
                    if (this.$route.path != '/chat'){
                      this.$router.push({ name: "chat" });
                    }else {
                      this.$router.go(0);
                    }
                  }, 1500);
                } else {
                  this.state = result.data.state;
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }, 2000);
          console.log("Timer ID:", this.timer);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },

    handleChangeTab(tab) {
      this.wxLoginUrl = null
      clearInterval(this.timer);
      console.log("当前的索引：" + tab);
      if (tab == 1) {
        this.createCode();
      }
    },
    submitFankForm(){
      const result = post("api/submitFankForm", this.submitFormItem)
          .then((result) => {
            this.$Message.success('提交成功');
            this.submitFank = false
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    },
    handleGetCaptcha(valid, { mobile, captcha }) {
      console.log(mobile)
      const result = post("api/sms/sendLoginCode", {
        phone : mobile
      })
          .then((result) => {
            // 这里的 result 就是 PromiseResult
            if (result.code === 1000) {
              this.$Message.warning(result.message);
            }else {
              this.$Message.success(result.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    },
    mouseover(event) {
      const currentSvg = event.currentTarget;

      // 在这里修改当前 SVG 的属性
      currentSvg.setAttribute("width", "45");
      currentSvg.setAttribute("height", "45");
    },
    mouseenter(event) {
      const currentSvg = event.currentTarget;

      // 在这里修改当前 SVG 的属性
      currentSvg.setAttribute("width", "40");
      currentSvg.setAttribute("height", "40");
    },
    handleSubmit(valid, { username, password }) {
      // if (valid) {
      //   this.$Modal.info({
      //     title: "输入的内容如下：",
      //     content: "username: " + username + " | password: " + password,
      //   });
      // }

    },
    handlePhoneSubmit (valid, { mobile, captcha }) {
      if (valid) {
        const result = post("api/auth/mobileLoginByCode", {
          phone : mobile,
          code : captcha,
        })
            .then((result) => {
              if (typeof result.data.token  !== "undefined") {
                this.$userStore.setUser(result.data.user);
                this.$userStore.setToken(result.data.token);
                this.$Message.success(result.message);
                setTimeout(() => {
                  if (this.$route.path != '/chat'){
                    this.$router.push({ name: "chat" });
                  }else {
                    this.$router.go(0);
                  }
                }, 1500);
              } else {
                this.$Message.error(result.message);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
      }
    },
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器，防止内存泄漏
    clearInterval(this.timer);
  },
};
</script>

<style>
.layout {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.ivu-layout{
  background-color: white!important;
}
.ivu-layout-footer{
  background-color: white!important;
}
.layout-logo {
  width: 100px;
  height: 30px;
  background: #ffffff!important;
  border-radius: 3px;
  float: left;
  position: relative;
  top: 15px;
  left: 20px;
}
.layout-nav {
  width: 806px;
  margin: 0 auto;
  margin-right: 20px;
}
.layout-footer-center {
  text-align: center;
  font-size: 12px;
  color: #495770;
  width: 100%;
  line-height:18px;
  overflow: overlay;
}
.dev-run-preview .dev-run-preview-edit {
  display: none;
}
  .chang{
    cursor: pointer;
  }
  h1{
    text-align: center;
    margin-bottom: 20px;
  }
.demo-login{
  width: 100%;
  min-height: 370px!important;
  margin: 15% auto !important;
}
  .multiple-shadows {
    box-shadow: 5px 5px 5px #888888, -5px -5px 5px #888888;
  }
  .demo-auto-login{
    margin-bottom: 24px;
    text-align: left;

  }
  .demo-auto-login a{
    float: right;
  }
  .ivu-layout-header{
    background: #ffffff!important;
    padding: 0 5px;
  }
.ivu-menu-horizontal.ivu-menu-light:after{
  display: none!important;
}
.ivu-drawer-body{
  padding: 5px;
}
.ivu-menu-vertical.ivu-menu-light:after{
  width: 0;
}
</style>
