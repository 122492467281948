<template>
  <div style="height: 100vh;
    width: 100vw;background-image: url(https://img.cdhbx.cn/uploads/mj/20240320/6a2c8040c3320b3f366d82b0c1ce7575.png);
    background-size: cover;
    background-position: center; /* 将背景图片居中 */
    ">
    <div style="height: 20%"></div>
    <div style="  width: 500px!important;background-color: #ffffff;margin: 0 auto;border-radius: 5px;height: 400px;position: relative">
      <div style="height: 10%"></div>
      <h1 style="text-align: center">欢迎登陆</h1>
      <Tabs v-model="tabsIndex" :events-enabled="true" @on-click="handleChangeTab" style="width: 308px;margin: 0 auto">
        <TabPane label="微信扫码登陆" icon="md-expand" name="1" @on-click="handleChangeTab">
          <div style="width: 60%; margin: 0 auto;position:relative;">
            <div v-if="loginExpired" style="margin: 12px; width: 87%;height: 87%;background-color: rgba(255,255,255,.9);position: absolute;z-index: 2;">
              <Icon size="36" style="margin-left: 40%;margin-top: 15%;margin-bottom: 10%" type="ios-information-circle-outline" />
              <p style="text-align: center;font-size: 16px;margin-bottom: 10px">二维码已失效</p>
              <p style="text-align: center;color: #2151d1;cursor: pointer" @click="createCode">点击刷新</p>
            </div>
            <Image :src="wxLoginUrl" :fit="fit" width="100%" height="100%" :alt="fit">
              <template #error>
                <div style="display: inline-block;width: 100%;height: 100%;position: relative;">
                  <Spin fix></Spin>
                </div>
              </template>
              <template #placeholder>
                <div style="display: inline-block;width: 100%;height: 100%;position: relative;">
                  <Spin fix></Spin>
                </div>
              </template>
            </Image>
            <Tag checkable color="success" v-if="state === 1" style="margin-top: 10px; margin-left: 38%">已扫码</Tag>
          </div>
        </TabPane>
        <TabPane icon="ios-phone-portrait" label="手机号码登陆" name="3">
          <Login @on-submit="handlePhoneSubmit">
            <Mobile name="mobile" />
            <Captcha
                name="captcha"
                :field="['mobile']"
                @on-get-captcha="handleGetCaptcha"
            />
            <Submit />
          </Login>
        </TabPane>
      </Tabs>
      <p style="text-align: center;position: absolute;bottom: 10px;left: 0;right: 0;">关注公众号「海边星科技」后自动注册和登录</p>
    </div>
    <Footer class="layout-footer-center" style="position: absolute;bottom:0 ">
      2023-2024 &copy; 成都海边星科技 <a style="color:#515a6e" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2024051673号-1</a>
      <img src="../../assets/beianIcon.png" style="width: 10px;margin-right: 5px; line-height: 12px;margin-left: 20px"/>
      <a  style="color:#515a6e" href="https://beian.mps.gov.cn/#/query/webSearch?code=51011502000686" rel="noreferrer" target="_blank">川公网安备51011502000686</a>
      <p>代理域名注册服务机构：广州云讯信息科技有限公司或腾讯云计算（北京）有限责任公司</p>
    </Footer>
  </div>
</template>
<script>
//#5fc157

import {post} from "@/common/api";
import {Footer} from "view-ui-plus";

export default {
  components: {Footer},
  data () {
    return {
      loginStatus: false,
      autoLogin: true,
      state: 0,
      // fit: true,
      tabsIndex: 0,
      wxLoginUrl: "",
      timer: null,
      codeId: 0,
      userData:null,
      fit: 'cover', // 图片适应方式
      isLoading: true, // 是否在加载中
      loginExpired:false
    }
  },
  created() {
    this.createCode()
  },
  methods: {
    handleImageLoad() {
      this.isLoading = false;
    },
    onLoginCancel(){
      this.loginStatus = false
      clearInterval(this.timer);
    },
    logins(){
      this.yue = 200000 + '元'
    },
    login() {
      this.loginStatus = true
      this.wxLoginUrl = null
      this.createCode()

      // const result = post("auth/login", {
      //   username: "admin",
      //   password: "user@example.com",
      // })
      //   .then((result) => {
      //     // 这里的 result 就是 PromiseResult
      //     if (result.code === 1000) {
      //       this.$Message.warning(result.message);
      //       return;
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
      // return;
    },
    logout() {
      this.$userStore.clearUser();
      this.$userStore.clearToken();
      this.$Message.success("退出登录成功");
      setTimeout(() => {
        this.$router.go(0);
      }, 1500);
    },
    createCode() {
      clearInterval(this.timer);
      this.wxLoginUrl = '';
      this.loginExpired = false
      post("api/auth/getSanQrCodeLogin", {})
          .then((result) => {
            console.log(result.code);
            if (result.code === 1000) {
              this.$Message.warning(result.message);
              return;
            }
            this.wxLoginUrl = result.data.url;
            this.timer = setInterval(() => {
              this.wxLoginUrl = result.data.url;
              this.codeId = result.data.id;
              post("api/auth/getScanLoginDetail", { id: this.codeId })
                  .then((result) => {
                    if (typeof result.data.token !== "undefined") {
                      this.$userStore.setUser(result.data.user);
                      this.$userStore.setToken(result.data.token);
                      this.$Message.success("登录成功");
                      clearInterval(this.timer);
                      setTimeout(() => {
                        if (this.$route.path !== '/originality'){
                          this.$router.push({ name: "/originality" });
                        }else {
                          this.$router.go(0);
                        }
                      }, 1500);
                    } else {
                      this.state = result.data.state;
                      //登陆过期
                      if (result.data.currentTime - result.data.loginTime > 60){
                        this.loginExpired = true
                        clearInterval(this.timer);
                      }
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
            }, 2000);
            console.log("Timer ID:", this.timer);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    },

    handleChangeTab(tab) {
      this.wxLoginUrl = null
      clearInterval(this.timer);
      console.log("当前的索引：" + tab);
      if (tab == 1) {
        this.createCode();
      }
    },
    handleGetCaptcha(valid, { mobile, captcha }) {
      console.log(mobile)
      const result = post("api/sms/sendLoginCode", {
        phone : mobile
      }).then((result) => {
            // 这里的 result 就是 PromiseResult
            if (result.code === 1000) {
              this.$Message.warning(result.message);
            }else {
              this.$Message.success(result.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    },
    mouseover(event) {
      const currentSvg = event.currentTarget;

      // 在这里修改当前 SVG 的属性
      currentSvg.setAttribute("width", "45");
      currentSvg.setAttribute("height", "45");
    },
    mouseenter(event) {
      const currentSvg = event.currentTarget;

      // 在这里修改当前 SVG 的属性
      currentSvg.setAttribute("width", "40");
      currentSvg.setAttribute("height", "40");
    },
    handleSubmit(valid, { username, password }) {
      // if (valid) {
      //   this.$Modal.info({
      //     title: "输入的内容如下：",
      //     content: "username: " + username + " | password: " + password,
      //   });
      // }

    },
    handlePhoneSubmit (valid, { mobile, captcha }) {
      clearInterval(this.timer);
      if (valid) {
        const result = post("api/auth/mobileLoginByCode", {
          mobile : mobile,
          code : captcha,
        }).then((result) => {
              if (typeof result.data.token  !== "undefined") {
                this.$userStore.setUser(result.data.user);
                this.$userStore.setToken(result.data.token);
                this.$Message.success(result.message);
                setTimeout(() => {
                  if (this.$route.path != '/chat'){
                    this.$router.push({ name: "chat" });
                  }else {
                    this.$router.go(0);
                  }
                }, 1500);
              } else {
                this.$Message.error(result.message);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
      }
    },
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器，防止内存泄漏
    clearInterval(this.timer);
  },
};
</script>

<style>

.demo-login{
  width: 400px!important;
  //min-height: 370px!important;
  //margin: 0 auto !important;
  background-color: #ffffff;
}
</style>

